import React from 'react';

import { TonConnectButton } from '@tonconnect/ui-react';

function App() {
  return (
    <>
      WORK <br />
      <TonConnectButton />
    </>
  );
}

export default App;
